#logo {
  max-width: 9rem;
  animation: slide-in 1.8s forwards;
}

#topbar {
  top: 0;
  left: 0;
  width: 100%;
}

.cart-icon {
  font-size: 1.4rem;
  font-weight: 700;
}

#btnRegister {
  background: #E6D8BD;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.19);
}

.nav-link {
  position: relative;
}

/* Active link styling */
.active-link {
  color: brown; /* Example active color */
  border-bottom: solid 3px brown; /* Active underline */
}


.nav-link:hover:after {
  transform: scaleX(1);
}

/* Default color */
.nav-link:not(.homepage):after {
  border-bottom: solid 3px brown;
}

/* White color on homepage */
.nav-link.homepage:after {
  border-bottom: solid 3px white;
}

/* Transition */
.nav-link:after {
  display: block;
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust as needed */
  left: 0;
  width: 100%;
  border-bottom: solid 3px transparent; /* Initially transparent */
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

@media screen and (max-width: 1024px) {
  #logo {
    max-width: 120px;
  }
}

@media screen and (max-width: 768px) {
  @keyframes slide-in {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

#menu {
  position: fixed;
  top: 0;
  font-size: 20px;
  right: 0;
  height: 100%;
  width: 100%; 
  background-color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 14;
}

#menu.open {
  transform: translateX(0);
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.15s;
  position: relative;
  z-index: 1100; /* Ensure the hamburger is above the menu */
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 29px;
  height: 2px;
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.hamburger.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.hamburger.open .hamburger-middle {
  display: none;
}

.hamburger.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

.hamburger.open .hamburger-top,
.hamburger.open .hamburger-middle,
.hamburger.open .hamburger-bottom {
  background-color: #000; /* Change to black when open */
}
