@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.cdnfonts.com/css/montserrat');
@import url('https://fonts.cdnfonts.com/css/copperplate-gothic-std');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

:root {
    --primary-font: 'Montserrat', sans-serif;;
    --secondary-font: "Roboto",sans-serif;
    --hero-font: 'Copperplate Gothic Std', sans-serif;
    --hero-two: "Playfair Display", serif;
    --primary-color: #65371F;
}

body {
    font-family: var(--primary-font);
}

/* Ensure the body and html take up the full height */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  flex: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#contact, #about, #bgshop, #emotions, #carting{
  animation: fadeIn 1.7s ease-in-out;
}
